<template>
  <div class="s-root" :style="{'background-image': `url(${require('@/assets/edu/background.png')})`}">
    <div class="s-top-banner">
      <div class="s-school-name" :style="{'background-image': `url(${require('@/assets/common/new-top-left.png')})`}">{{ schoolName }}</div>
      <div class="s-title" :style="{'background-image': `url(${require('@/assets/common/new-top.png')})`}">互联网+明厨亮灶监管平台 信息公示看板</div>
      <div class="s-time" :style="{'background-image': `url(${require('@/assets/common/new-top-right.png')})`}">{{ now + " " + week }}</div>
    </div>
    <div class="s-content">
      <el-row :gutter="15" class="s-row-1">
        <el-col :span="8" class="s-col">
          <BaseInfo ref="baseInfo" />
        </el-col>
        <el-col :span="8" class="s-col">
          <MorningCheck ref="morningCheck" />
        </el-col>
        <el-col :span="8" class="s-col">
          <IotMonitor ref="iotMonitor" />
        </el-col>
      </el-row>
      <el-row class="s-row-2" :gutter="15">
        <el-col :span="8" class="s-col">
          <PublicMenu ref="publicMenu" />
        </el-col>
        <el-col :span="16">
          <el-row class="s-row-3">
            <el-col :span="24" class="s-col">
              <IngrdientsPurchase ref="purchase" />
            </el-col>
          </el-row>
          <el-row class="s-row-3">
            <el-col :span="24" class="s-col">
              <WarningInfo ref="warningInfo" />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <!-- <div class="toolbar" :style="{'background-image': `url(${require('@/assets/common/14x300.png')})`}" @click="toggle" /> -->
    <Menu ref="menu" current="today" @evt="menuEvt" />
  </div>
</template>

<script>
import { schoolDetail } from '@/api/school/school'
import BaseInfo from './component/BaseInfo.vue'
import MorningCheck from './component/MorningCheck.vue'
import IotMonitor from './component/IotMonitor.vue'
import PublicMenu from './component/PublicMenu.vue'
import IngrdientsPurchase from './component/IngrdientsPurchase.vue'
import WarningInfo from './component/WarningInfo.vue'
import Menu from '../edu/component/Menu.vue'
export default {
  components: {
    BaseInfo,
    MorningCheck,
    IotMonitor,
    PublicMenu,
    IngrdientsPurchase,
    WarningInfo,
    Menu
  },
  props: {
    school: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      now: '',
      week: '',
      schoolName: '',
      h: '',
      w: ''
    }
  },
  mounted() {
    this.h = window.innerHeight
    this.w = window.innerWidth
    const date = new Date()
    this.now = date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate()
    this.week = '星期' + '日一二三四五六'.charAt(new Date().getDay())
    this.init()
  },
  methods: {
    init() {
      schoolDetail(this.school).then(res => {
        this.schoolName = res.data.companyName
      })

      this.refresh60Min()
      this.refresh5Min()

      // 以下60分钟刷新
      setInterval(this.refresh60Min, 60 * 60 * 1000)
      // 以下5分钟刷新
      setInterval(this.refresh5Min, 5 * 60 * 1000)
    },
    toggle() {
      this.show = !this.show
      this.$refs.menu.toggle(this.show)
    },
    menuEvt(evtName) {
      this.show = false
      if (evtName === 'video') {
        this.$router.push({ path: '/camera-video', query: { school: this.school }})
      } else if (evtName === 'dashboard') {
        this.$router.push({ path: '/edu-dashboard' })
      } else if (evtName === 'back') {
        this.$router.push({ path: '/edu-dashboard' })
      }
    },
    refresh5Min() {
      this.$refs.iotMonitor.init(this.school)
      this.$refs.warningInfo.init(this.school)
      const date = new Date()
      this.now = date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate()
      this.week = '星期' + '日一二三四五六'.charAt(new Date().getDay())
    },
    refresh60Min() {
      this.$refs.baseInfo.init(this.school)
      this.$refs.morningCheck.init(this.school)
      this.$refs.publicMenu.init(this.school)
      this.$refs.purchase.init(this.school)
    }
  }
}
</script>

<style lang="scss" scoped>
.s-root {
  height: 100vh;
  width: 100vw;
  background-size: 100% 100%;
  color: white;
  font-family: 思源黑体;
  font-weight: bold;
  overflow: hidden;
}
.s-top-banner {
  height: calc(10vh);
  display: flex;
  justify-content: space-between;
  line-height: calc(10vh);
  align-items: center;
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
  .s-school-name {
    height: 40px;
    width: 268px;
    line-height: 30px;
     font-size: 16px;
  }
  .s-title {
    height: 50px;
    width: 692px;
    line-height: 45px;
    font-size: 20px;
    font-weight: bold;
  }
  .s-time {
    height: 40px;
    width: 168px;
    line-height: 38px;
     font-size: 16px;
  }
}
.s-content {
  padding: 0px 36px;
}
.s-row-1 {
  height: calc(30vh);
  margin-bottom: 10px;
}
.s-row-2 {
  height: calc(46vh) ;
  margin-top: 70px;
}
.s-row-3 {
  // height: calc(13vh);
  height: 170px !important;
  overflow: hidden;

}
.s-col {
  height: 100%;
}
.toolbar {
  height: 300px;
  width: 14px;
  position: absolute;
  top: 150px;
  right: 0px;
  cursor: pointer;
}
</style>
