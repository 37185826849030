<template>
  <div class="root inner" :style="{'background-image': `url(${require('@/assets/school/1222x280.png')})`}">
    <div class="title">食材采购公示</div>
    <div class="content">
      <el-row :gutter="20">
        <el-col :span="17">
          <div class="list">
            <el-row class="list-header">
              <el-col :span="9" :offset="1">供应商</el-col>
              <el-col :span="6">名称规格</el-col>
              <el-col :span="4">采购数量</el-col>
              <el-col :span="4">单价</el-col>
            </el-row>
            <vue-seamless-scroll :data="ingredients" :class-option="classOption" class="scoll">
              <el-row v-for="(item, index) in ingredients" :key="index" class="list-content">
                <el-col :span="9" :offset="1">{{ item.partnerName }}</el-col>
                <el-col :span="6">{{ item.categoryName }}</el-col>
                <el-col :span="4">{{ item.amount }}</el-col>
                <el-col :span="4">{{ item.price }}</el-col>
              </el-row>
            </vue-seamless-scroll>
          </div>
        </el-col>
        <el-col :span="6">
          <el-carousel height="280px" indicator-position="none" arrow="never">
           <el-carousel-item v-for="(item, index) in img" :key="index">
              <div class="img_d">
                <img :src="item.img" width="100%" height="80px">
              </div>
            </el-carousel-item>
          </el-carousel>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { ingredientsPurchaseInfo } from '@/api/school/school'
export default {
  data() {
    return {
      classOption: {
        singleHeight: 24 * 5,
        limitMoveNum: 5
      },
      ingredients: [],
      img: []
    }
  },
  methods: {
    init(school) {
      ingredientsPurchaseInfo(school).then(res => {
        this.ingredients = res.data.ingredients
        this.img = res.data.img
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import './BaseStyle.scss';
.root{
  height: 180px;
}
.content {
  margin-left: 25px;
  font-size: 10px;

}
.list {
  height: 20px;
  .list-header {
    height: 24px;
    line-height: 24px;
    background: #0e5dfb;
  }
  .scoll {
    // height: 25px;
    overflow: hidden;
  }
  .list-content {
    height: 24px;
    line-height: 24px;
    border-bottom: 2px solid #0e5dfb;
    font-weight: 100;
  }
}
.img {
  height: 60px;
  width: 100%;
  background-color: #fff;
}
.img_d {
  height: 80px;
  width: 100%;
  background-color: #fff;
}
</style>
