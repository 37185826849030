<template>
  <div
    class="root inner"
    :style="{ 'background-image': `url(${require('@/assets/school/1222x280.png')})` }"
  >
    <div class="title">预警信息</div>
    <div class="content">
      <vue-seamless-scroll :data="warningList" :class-option="classOption" class="scroll">
        <!-- <div class="img-box">
          <div v-for="(item, index) in warningList" :key="index" class="img">
            <img v-if="item.imgUrl" :src="item.imgUrl" width="220px" height="138px">
            <div v-else style="padding-top:10px">
              <div>{{ item.title }}</div>
              <div>{{ item.noticeContent }}</div>
            </div>
          </div>
        </div> -->

        <div class="img-box">
          <div v-for="(item, index) in warningList" :key="index" class="img">
            <div>
              <div>
                <span>{{ item.createAt }}</span>
              </div>
              <div>
                <span>{{ item.noticeContent }}</span>
              </div>
            </div>
            <div>
              <!-- <img v-if="item.imgUrl" :src="item.imgUrl" width="220px" height="80px" /> -->
            </div>
          </div>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import { warningInfo } from '@/api/school/school'
export default {
  data() {
    return {
      listData: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      classOption: {
        direction: 2,
        singleWidth: 244,
        limitMoveNum: 6
      },
      warningList: []
    }
  },
  methods: {
    init(school) {
      warningInfo(school).then(res => {
        this.warningList = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import './BaseStyle.scss';
.root{
  height: 180px;
}
.content {
  color: #0e5dfb;
  margin-left: 25px;
  font-size: 10px;
}
.scroll {
  width: 95%;
  height: 85px;
  overflow: hidden;
}
.img-box {
  display: flex;
}

.img {
  padding: 5px;
  width: 210px;
  height: 60px;
  margin-right: 16px;
  // background-color: #fff;
  border: 4px solid #0e5dfb;
  color: #fff;
  border-radius: 10px;
}
</style>
