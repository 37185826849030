import request from '@/axios'

const baseUrl = 'school'

/**
 * 食堂基本信息
 * @param {*} school
 * @returns
 */
export const baseInfo = (school) => {
  return request({
    url: baseUrl + '/base/' + school,
    method: 'get'
  })
}

/**
 * 健康证信息
 * @param {*} school
 */
export const healthCardInfo = (school) => {
  return request({
    url: baseUrl + '/health-card/' + school,
    method: 'get'
  })
}

/**
 * 食材采购公示-检疫照片
 * @param {*} school
 * @returns
 */
export const ingredientsPurchaseInfo = (school) => {
  return request({
    url: baseUrl + '/ingredients-purchase/' + school,
    method: 'get'
  })
}

/**
 * 公开菜谱公式
 * @param {*} school
 * @returns
 */
export const publicMenuInfo = (school) => {
  return request({
    url: baseUrl + '/public-menu/' + school,
    method: 'get'
  })
}

/**
 * 晨检信息
 * @param {*} school
 * @returns
 */
export const morningCheckInfo = (school) => {
  return request({
    url: baseUrl + '/morning-check/' + school,
    method: 'get'
  })
}

/**
 * 物联监测
 * @param {*} school
 */
export const iotMonitor = (school) => {
  return request({
    url: baseUrl + '/iot-monitor/' + school,
    method: 'get'
  })
}

/**
 * 预警信息
 * @param {*} school
 */
export const warningInfo = (school) => {
  return request({
    url: baseUrl + '/warning-info/' + school,
    method: 'get'
  })
}

/**
 * 获取学校信息
 * @param {*} school
 * @returns
 */
export const schoolDetail = (school) => {
  return request({
    url: '/company/' + school,
    method: 'get'
  })
}
