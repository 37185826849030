<template>
  <div class="menu-root" :class="{'show': show}">
    <div class="content">
      <div class="item" :class="{'current': current === 'today'}" :style="{'background-image': `url(${require('@/assets/common/menu/200x65.png')})`}" @click="$emit('evt', 'today')" />
      <div class="item" :class="{'current': current === 'video'}" :style="{'background-image': `url(${require('@/assets/common/menu/qietu-29.png')})`}" @click="$emit('evt', 'video')" />
      <div class="item" :class="{'current': current === 'dashboard'}" :style="{'background-image': `url(${require('@/assets/common/menu/qietu-30.png')})`}" @click="$emit('evt', 'dashboard')" />
      <div class="item" :class="{'current': current === 'back'}" :style="{'background-image': `url(${require('@/assets/common/menu/qietu-31.png')})`}" @click="$emit('evt', 'back')" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    current: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    toggle(show) {
      this.show = show
    }
  }
}
</script>

<style scoped lang="scss">
.menu-root {
  width: 200px;
  height: 280px;
  position: absolute;
  right: -200px;
  display: none;
  top: 155px;
}
.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.item {
  width: 200px;
  height: 65px;
  cursor: pointer;
}
.show {
  right: 14px !important;
  display: block;
}
.current {
  background-color: #0e5dfb;
  border-radius: 45px 0 0 45px;
}
</style>
